<template>
  <div class="container home"></div>
</template>

<script>
export default {
  name: "Home",
  components: {
    //  HelloWorld,
    //  projCard,
  },
  data() {
    return {
      p: {},
      user: "",
      pass: "",
      //   projs: [],
      posting: false,
      // loadingOps: true,
    };
  },
  computed: {
    posted() {
      return { h: String(this.user).trim(), p: String(this.pass).trim() };
    },
  },
  methods: {
    logUserOut() {
      // alert(3);

      this.$store.main
        .dispatch("logout")
        .then(() => {
          console.log(432432, "LOGGED OUT");
        })
        .catch((e) => {
          console.log(e), "EIRIR 324";
        })
        .then(() => {
          this.$router.replace("/"); //REDIRECT!
          //buefy toast
          this.$buefy.toast.open({
            message: "See you soon!",
            type: "is-dark",
            position: "is-bottom-left",
            duration: 9000,
          });
          //alert(3532);
        });
      /*
            localStorage.removeItem("userAuthToken");
            this.$router.push("/login");
            alert('logged out. token removed brooooo')
            */
    },

    printToConsole() {
      console.log(this.user);
    },
    processForm(e) {
      e.preventDefault(); //for safari
      // Send data to the server or update your stores and such.
      // alert(33);
      this.posting = true;
      /*
      window.API.loginPass()
        .then((data) => {
          this.$buefy.toast.open({
            message: "Loading your profile...",
            type: "is-success",
          });
*/
      console.log("FORM SUBMIT JANDler... in login...");
      this.$store.main
        .dispatch("loginPass", this.posted)
        //.dispatch("setProfileToken", data)
        .then(() => {
          //alert('TODO: close login modal mutation...')
          this.$router.push("/?justLogged=1");
        })
        .catch((err) => {
          console.log(err);
          console.log("ERR AUTH ........");

          //buefy toast
          this.$buefy.toast.open({
            message: "Wrong username or password",
            type: "is-danger",
          });

          // this.$router.push("/login?wrongpass=1");
        })
        .then((succ) => {
          this.posting = false;
        });
    },
  },
  mounted() {
    this.logUserOut();
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
      window.API.getProjectOperations(this.$route.params.project).then((ops) => {
        var opId = this.$route.params.operation;
        this.ops = ops;
        this.loadingOps = false;
        this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
      });*/
  },
};
</script>

<style></style>
